import React, { useEffect } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import { Button } from 'antd';
import { useUserProvider } from '@/context/UserProvider';
import { useRouter } from 'next/router';

const TopContent = () => {
  const router = useRouter();
  const { t } = useTranslation('common');
  const {
    onOpenSelectReportsAlert,
    // onOpenCreateReportAlert,
    onGetStartedClick,
    onOpenLoginModal,
    isLogin,
  } = useUserProvider();

  useEffect(() => {
    onGetStartedClick('none');
  }, []);

  const onDashboardClick = () => {
    if (isLogin) {
      onOpenSelectReportsAlert(true);
      // onOpenCreateReportAlert(true);
    } else {
      onGetStartedClick('Single');
      onOpenLoginModal(true);
    }
  };

  return (
    <div className={styles.backContent}>
      <div className={styles.content}>
        <h1 style={{ width: 0, height: 0, display: 'none' }}>
          {t('planbowl_Title')}
        </h1>
        <div className={styles.title}>
          <span>{`${t('Invest in Safety with PlanBowl')}: `}</span>
          <span>{t('Customizable Guides')}</span>
          <span>{` ${t('to')} `}</span>
          <span>{t('Keep Your Loved Ones Safe')}</span>
        </div>
        <p>
          {t(
            `Create personalized disaster preparedness guides in under a minute to ensure you and your loved ones are ready for any emergency`,
          )}
        </p>
        <div className={styles.btnContainer}>
          <div>
            <Button
              className={`${styles.btn} ${styles.exampleBtn}`}
              onClick={() => router.push('/sample-guide')}
            >
              {t('Sample Guide')}
            </Button>
          </div>
          <div>
            <Button
              className={`${styles.btn} ${styles.getStartBtn}`}
              onClick={() => onDashboardClick()}
            >
              {t('Get Started')}
            </Button>
            <span className={styles.tip}>{t('$19.9 Limited Offer')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopContent;
